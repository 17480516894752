.career {
    /* background: url(../../Assets/Images/Main\ →\ Section.webp);
    background-repeat: no-repeat;
    background-size: cover; */
    padding: 100px;
    background-color: #000;

}

.career .top-case {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.career .top-case img {
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.career .top-case h6 {
    color: #04a472;
    font-size: 18px;
}



.career h4 {
    font-size: 30px;
    color: #fff;
    margin-bottom: 130px;
}

.career .career-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 80px;
}

.career .items {
    width: 26%;
    background-color: #000;
    border-top-left-radius: 40px;
    border-bottom-right-radius: 40px;
    box-shadow: rgba(241, 239, 239, 0.2) 0px 2px 8px 0px;
    position: relative;
    overflow: hidden;
    height: 460px;
}

.career .items .img-addition {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.career .items .img-addition img.icon {
    width: 50px;
    border-radius: 0;
    position: absolute;
    left: 20px;
    bottom: 210px;
    display: none;
}

.career .items .img-addition img.shape {
    width: 130px;
    position: absolute;
    right: 0;
    bottom: 160px;
}

.career .items .img-box {
    margin-bottom: 30px;
}

.career .items img.main {
    width: 100%;
    display: block;
    border-top-left-radius: 40px;
    transition: all ease-in-out .6s;
    height: 220px;
    position: relative;
    z-index: 1;
}

.career .items:hover img.main {
    transform: scale(1.25);
}

.career .content-box {
    padding: 20px;
}

.career .items h6 {
    margin-bottom: 20px;
    color: #fff;
    font-size: 24px;
}

.career .items p {
    color: #fff;
    margin-bottom: 20px;
}

.career .items button {
    background: transparent;
    color: #fff;
    outline: none;
    border: none;
}

@media (max-width: 1280px) {
    .career .career-items {
        gap: 50px;
    }

    .career {
        padding: 100px 50px;
    }

    .career .items {
        width: 30%;
    }
}

@media (max-width: 1100px) {
    .career .items {
        width: 38%;
    }

    .career .career-items {
        gap: 80px;
    }
}

@media (max-width: 980px) {
    .career .items {
        width: 42%;
    }
}

@media (max-width: 700px) {
    .career .items {
        width: 60%;
    }

    .career h4 {
        margin-bottom: 80px;
    }

}

@media (max-width: 640px) {
    .career .items {
        width: 80%;
    }

    .career .top-case h6 {
        font-size: 16px;
    }

    .career .top-case img {
        width: 24px;
        height: 20px;
    }
}

@media (max-width: 480px) {
    .career .items {
        width: 96%;
    }
}