.testimonial {
    background-color: #000;
    padding: 80px 50px;
    overflow: hidden;
}

.testimonial .top-case {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
}

.testimonial .top-case img {
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.testimonial .top-case h6 {
    color: #04a472;
    font-size: 18px;
}

.testimonial h3 {
    font-size: 48px;
    color: #fff;
    margin-bottom: 50px;
    text-align: center;
}

.testimonial .test-items {
    width: 95%;
    margin: 0 auto;
}

.testimonial .item {
    width: 86% !important;
    background-color: #0A0A0A;
    padding: 20px 20px 40px 20px;
    box-shadow: rgba(248, 245, 245, 0.2) 0px 2px 8px 0px;
    height: 260px;
}


.testimonial .item p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 50px;
    margin-top: 30px;
    width: 90%;
    margin: 30px auto 50px;
}

.testimonial .item .star {
    width: 100px;
}

.testimonial .item .item-bottom {
    width: 90%;
    display: flex;
    justify-content: space-between;
}

.testimonial .item .item-bottom .gree {
    height: 60px;
    width: 60px;
    background-color: #04a472;
    position: absolute;
    top: 0;
    left: 0;
}

.testimonial .item .item-bottom .img-box {
    margin-right: 90px;
    position: relative;
}

.testimonial .item .item-bottom img.person {
    width: 60px;
    height: 60px;
    position: absolute;
    left: 10px;
    top: -10px;
}

.testimonial .item .item-bottom .image-name {
    display: flex;
}

.testimonial .item .item-bottom .image-name h5,
h6 {
    color: #fff;
    font-size: 16px;
}

/* .testimonial .swiper-slide {
    width: 40% !important;
} */

.testimonial .swiper-wrapper {
    justify-content: center;
}


@media (max-width: 1100px) {
    .testimonial .item {
        width: 100% !important;
    }
}

@media (max-width: 480px) {
    .testimonial {
        padding: 80px 10px;
    }

    .testimonial h3 {
        font-size: 30px;
    }

    .testimonial .item p {
        font-size: 16px;
    }
}