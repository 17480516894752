footer {
    background-color: #0A0A0A;
    padding-top: 100px;
}

footer .footer-top {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #fff;
    padding: 0 60px 80px 60px;
}

footer .footer-logo {
    width: 24%;
}

footer .footer-logo .logo {
    width: 200px;
    margin-bottom: 40px;
}

footer .footer-logo img {
    display: block;
    width: 100%;
}

footer .footer-logo .social {
    display: flex;
    gap: 10px;
}

footer .footer-logo .social li {
    border: 1px solid #535353;
    padding: 8px;
}

footer .footer-logo .social li svg {
    color: #fff;
    transition: .4s ease-in-out;
}

footer .footer-logo .social li:hover svg {
    color: #04a472;
    transform: scale(1.5);
}

footer .solution {
    width: 20%;
}

footer .quik {
    width: 20%;
}

footer .contact {
    width: 27%;
}

footer .solution h3,
footer .quik h3,
footer .contact h3 {
    color: #fff;
    font-size: 24px;
    margin-bottom: 20px;
}

footer .solution li,
footer .quik li {
    color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

footer .solution li svg,
footer .quik li svg {
    margin-right: 10px;
}

footer .contact p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 10px;
}

footer .contact p p {
    margin-top: 10px;
}

footer .contact h4 {
    color: #fff;
    font-size: 18px;
    margin-bottom: 10px;
}

footer .contact .phone {
    display: flex;

}

footer .contact .phone a {
    color: #fff;
}

footer .contact .phone a:first-child {
    margin-right: 14px;
}

footer .footer-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 200px;
}

footer .footer-bottom p {
    font-size: 16px;
    color: #fff;
}

footer .footer-bottom ul {
    display: flex;
    align-items: center;
}

footer .footer-bottom ul li {
    font-size: 16px;
    color: #fff;
}

footer .footer-bottom ul li:first-child {
    margin-right: 20px;
}

@media (max-width: 1100px) {
    footer .footer-top {
        padding: 0 40px 80px 40px;
    }

    footer .contact {
        width: 31%;
    }

}

@media (max-width: 1000px) {
    footer .footer-top {
        flex-wrap: wrap;
        padding: 0 60px 80px 60px;
        justify-content: space-between;
    }

    footer .footer-logo {
        width: 45%;
    }

    footer .solution {
        width: 42%;
        margin-bottom: 30px;
    }

    footer .quik {
        width: 45%;
    }

    footer .contact {
        width: 42%;
    }

    footer .footer-bottom {
        padding: 40px 100px;
    }

    footer .footer-bottom p,
    footer .footer-bottom ul li {
        font-size: 14px;
    }
}

@media (max-width: 800px) {
    footer .solution {
        width: 54%;
    }

    footer .contact {
        width: 54%;
    }

    footer .footer-bottom {
        padding: 40px;
    }
}

@media (max-width: 640px) {
    footer .footer-logo {
        width: 100%;
        margin-bottom: 50px;
    }

    footer .solution {
        width: 100%;
        margin-bottom: 50px;
        display: none;
    }

    footer .quik {
        width: 100%;
        margin-bottom: 50px;
        display: none;
    }

    footer .contact {
        width: 100%;
    }

    footer .footer-logo .logo {
        margin: 0 auto 40px;
    }

    footer .footer-logo .social {
        justify-content: center;
    }

    footer .solution h3,
    footer .quik h3,
    footer .contact h3,
    footer .contact {
        text-align: center;
    }

    footer .solution li,
    footer .quik li,
    footer .contact .phone {
        justify-content: center;
    }

    footer .footer-bottom {
        flex-wrap: wrap;
        justify-content: center;
    }

    footer .footer-bottom p {
        margin-bottom: 10px;
        width: 100%;
        text-align: center;
    }

    footer .footer-bottom ul {
        width: 100%;
        justify-content: center;
    }
}

@media (max-width: 480px) {
    footer .footer-logo .logo {
        width: 160px;
    }

    footer .solution h3,
    footer .quik h3,
    footer .contact h3 {
        font-size: 20px;
    }

    footer .solution li,
    footer .quik li,
    footer .contact .phone a {

        font-size: 14px;
    }

    footer .contact p {
        font-size: 16px;
    }

    footer .footer-top {
        padding: 0 40px 80px 40px;
    }

}