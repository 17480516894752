header {
    padding: 0 50px;
    display: flex;
    justify-content: space-between;
    height: 80px;
    align-items: center;
    border-bottom: 1px solid #fff;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    box-sizing: border-box;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

header .logo {
    width: 200px;
}

header .logo img {
    display: block;
    width: 100%;
}

header ul {
    display: flex;
    gap: 30px;
}

header ul li {
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}

.link {
    color: #fff;
}

header ul li::after {
    content: "";
    width: 100%;
    display: block;
    height: 2px;
    background-color: #04a472;
    transform: translateY(10px);
    opacity: 0;
    border-radius: 20px;
    transition: .3s ease-in-out;
}

header ul li.active::after {
    opacity: 1;
    transform: translateY(6px);
}

header ul li:hover::after {
    opacity: 1;
}

header button {
    color: #fff;
    background: linear-gradient(90deg, rgba(4, 164, 114, 1) 0%, rgba(0, 6, 12, 1) 94%);
    padding: 18px 30px;
    border: none;
    font-size: 16px;
    letter-spacing: 1px;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 #fff;
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-out 1s;
    display: inline-block;
}

header button a {
    color: #fff;
}

header button:hover {
    box-shadow: inset 400px 50px 0 0 #fff;
}

header button:hover a {
    color: #000;
}

.menu {
    display: none;
    width: 30px;
}

.open .menu span:nth-child(1) {
    transform: rotate(45deg) translateX(14px);
}

.open .menu span:nth-child(3) {
    transform: rotate(-45deg) translateX(14px);
}

.open .menu span:nth-child(2) {
    transform: scaleX(0);
}

.menu span {
    width: 100%;
    display: block;
    background-color: #fff;
    height: 3px;
    transition: .3s ease-in-out;
    border-radius: 35px;
}

.menu span:nth-child(2) {
    margin: 6px 0;
}


@media (max-width: 900px) {
    header .logo {
        width: 150px;
    }

    header ul li {
        font-size: 16px;
    }

    header button {
        padding: 12px 24px;
    }
}

@media (max-width: 800px) {
    header ul {
        display: none;
    }

    header button {
        display: none;
    }

    .menu {
        display: block;
    }
}

@media (max-width: 480px) {
    header {
        padding: 0 24px;
    }
}