.mobile .nav {
    background-color: #000000c9;
    width: 100%;
    padding: 80px 0;
    position: absolute;
    top: 12%;
    right: 0;
    opacity: 0 !important;
    display: block;
    transition: .2s ease-in-out;
    text-align: center;
    display: none;
}

.mobile .nav li {
    font-size: 24px;
    margin-bottom: 40px;
    color: #fff;
}

.mobile .nav.open {
    opacity: 1 !important;
    display: block;
}

.mobile .nav.close {
    font-size: 30px;
    margin-bottom: 50px;
    position: absolute;
    top: 30px;
}