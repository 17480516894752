.appdetails {
    background-color: #000;
    padding: 100px 80px;
}

.appdetails .top-case {
    margin-bottom: 20px;
    padding: 0 100px;
    width: 60%;
}

.appdetails .top-case img {
    width: 30px;
    height: 30px;
    margin-bottom: 20px;
}



.appdetails h4 {
    font-size: 30px;
    color: #fff;
    margin-bottom: 60px;
}

.appdetails .career-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 80px;
}

.appdetails .items {
    width: 26%;
    background-color: #000;
    border-top-left-radius: 40px;
    border-bottom-right-radius: 40px;
    box-shadow: rgba(241, 239, 239, 0.2) 0px 2px 8px 0px;
    position: relative;
    overflow: hidden;
    height: 460px;
}

.appdetails .items .img-addition {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.appdetails .items .img-addition img.icon {
    width: 50px;
    border-radius: 0;
    position: absolute;
    left: 20px;
    bottom: 210px;
    display: none;
}

.appdetails .items .img-addition img.shape {
    width: 130px;
    position: absolute;
    right: 0;
    bottom: 160px;
}

.appdetails .items .img-box {
    margin-bottom: 30px;
}

.appdetails .items img.main {
    width: 100%;
    display: block;
    border-top-left-radius: 40px;
    transition: all ease-in-out .6s;
    height: 220px;
    position: relative;
    z-index: 1;
}

.appdetails .items:hover img.main {
    transform: scale(1.25);
}

.appdetails .content-box {
    padding: 20px;
}

.appdetails .items h6 {
    margin-bottom: 20px;
    color: #fff;
    font-size: 24px;
}

.appdetails .items p {
    color: #fff;
    margin-bottom: 20px;
}

.appdetails .items button {
    background: transparent;
    color: #fff;
    outline: none;
    border: none;
}


@media (max-width: 1280px) {
    .appdetails .career-items {
        gap: 50px;
    }

    .appdetails {
        padding: 100px 50px;
    }

    .appdetails .items {
        width: 30%;
    }
}

@media (max-width: 1100px) {
    .appdetails .items {
        width: 38%;
    }

    .appdetails .career-items {
        gap: 80px;
    }

    .appdetails .top-case {
        width: 62%;
    }
}

@media (max-width: 980px) {
    .appdetails .items {
        width: 42%;
    }

    .appdetails .top-case {
        padding: 0 50px;
        width: 80%;
    }
}

@media (max-width: 800px) {
    .appdetails .top-case {
        padding: 0;
        width: 100%;
        text-align: center;
    }

}

@media (max-width: 700px) {
    .appdetails .items {
        width: 60%;
    }

    .appdetails h4 {
        margin-bottom: 80px;
    }

}

@media (max-width: 640px) {
    .appdetails .items {
        width: 80%;
    }

    .appdetails .top-case h6 {
        font-size: 16px;
    }

    .appdetails .top-case img {
        width: 24px;
        height: 20px;
    }

    .appdetails h4 {
        font-size: 28px;
    }
}

@media (max-width: 480px) {
    .appdetails .items {
        width: 96%;
    }

    .appdetails h4 {
        font-size: 22px;
    }
}