.spotlight-intern {
    background: url(../../Assets/Images/internshipbanner.png);
    background-repeat: no-repeat;
    background-size: contain;
    height: 100vh;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 230px;
}

.spotlight-intern .content {
    width: 50%;
}

.spotlight-intern .content h4 {
    color: #fff;
    font-size: 40px;
    margin-bottom: 20px;
}

.spotlight-intern .content p {
    color: #fff;
    font-size: 18px;
}

@media (max-width: 1280px) {
    .spotlight-intern {
        padding-right: 150px;
    }

    .spotlight-intern .content {
        width: 52%;
    }
}

@media (max-width: 1100px) {
    .spotlight-intern .content {
        width: 58%;
    }

    .spotlight-intern {
        padding-right: 40px;
        height: 90vh;
    }
}

@media (max-width: 1000px) {
    .spotlight-intern .content h4 {
        font-size: 34px;
    }

    .spotlight-intern .content p {
        font-size: 16px;
    }
}

@media (max-width: 800px) {
    .spotlight-intern .content h4 {
        font-size: 30px;
    }

    .spotlight-intern .content {
        width: 60%;
    }

    .spotlight-intern {
        background-size: cover;
        padding: 0;
        justify-content: center;
    }
}

@media (max-width: 640px) {
    .spotlight-intern .content {
        width: 75%;
    }
}

@media (max-width: 480px) {
    .spotlight-intern .content h4 {
        font-size: 22px;
    }

    .spotlight-intern .content p {
        font-size: 14px;
    }
}