.tools {
    background-color: #000;
    padding: 100px 150px;
}

.tools h4 {
    color: #fff;
    font-size: 40px;
    margin-bottom: 50px;
}

.tools .used {
    display: flex;
    flex-wrap: wrap;
    gap: 80px;
    justify-content: center;
}

.tools .used li {
    width: 60px;
    transition: .4s ease-in;
    -webkit-animation: slide-in-elliptic-left-fwd 5s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate both;
    animation: slide-in-elliptic-left-fwd 5s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate both;
}

.tools .used li:nth-child(2n) {
    -webkit-animation: slide-in-elliptic-right-fwd 5s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate both;
    animation: slide-in-elliptic-right-fwd 5s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate both;
}


.tools .used li img {
    display: block;
    width: 100%;
}

@media (max-width: 1000px) {
    .tools {
        padding: 100px;
    }
}

@media (max-width: 800px) {
    .tools {
        padding: 50px;
    }

    .tools .used li {
        width: 70px;
    }
}


@keyframes slide-in-elliptic-left-fwd {
    0% {
        -webkit-transform: translateX(-800px) rotateY(30deg) scale(0);
        transform: translateX(-800px) rotateY(30deg) scale(0);
        -webkit-transform-origin: -100% 50%;
        transform-origin: -100% 50%;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) rotateY(0) scale(1);
        transform: translateX(0) rotateY(0) scale(1);
        -webkit-transform-origin: 1800px 50%;
        transform-origin: 1800px 50%;
        opacity: 1;
    }
}

@keyframes slide-in-elliptic-right-fwd {
    0% {
        -webkit-transform: translateX(800px) rotateY(-30deg) scale(0);
        transform: translateX(800px) rotateY(-30deg) scale(0);
        -webkit-transform-origin: -100% 50%;
        transform-origin: -100% 50%;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateX(0) rotateY(0) scale(1);
        transform: translateX(0) rotateY(0) scale(1);
        -webkit-transform-origin: -1800px 50%;
        transform-origin: -1800px 50%;
        opacity: 1;
    }
}