* {
    list-style: none;
    text-decoration: none;
    outline: none;
    padding: 0;
    margin: 0;
}

.loader-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
}