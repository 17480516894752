.about {
    padding: 100px 150px;
    display: flex;
    justify-content: space-between;
    background-color: #000;
}

.about .about-img {
    width: 50%;
    position: relative;
}

.about .about-img .img-b {
    width: 400px;
}

.about .about-img .img-b img {
    display: block;
    width: 100%;
}

.about .about-img .img-s {
    width: 220px;
    position: absolute;
    left: 300px;
    bottom: 70px;
}

.about .about-img .img-s img {
    display: block;
    width: 100%;
}

.about .about-content {
    width: 42%;
}

.about .about-content h3 {
    font-size: 40px;
    color: #fff;
    margin-bottom: 20px;
}

.about .about-content h3 span {
    color: #04a472;
}

.about .about-content p {
    font-size: 18px;
    color: #fff;
    margin-bottom: 20px;
}

.about .about-content button {
    color: #fff;
    background: linear-gradient(90deg, rgba(4, 164, 114, 1) 0%, rgba(0, 6, 12, 1) 94%);
    padding: 18px 30px;
    border: none;
    font-size: 16px;
    letter-spacing: 1px;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 #fff;
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-out 1s;
    display: inline-block;
}

.about .about-content button:hover {
    color: #000;
    box-shadow: inset 400px 50px 0 0 #fff;
}

@media (max-width: 1280px) {
    .about {
        padding: 100px;
    }

    .about .about-img .img-s {
        left: 250px;
    }

    .about .about-img .img-b {
        width: 370px;
    }
}

@media (max-width: 1100px) {
    .about .about-img .img-b {
        width: 330px;
    }

    .about .about-img .img-s {
        left: 220px;
        width: 200px;
    }
}

@media (max-width: 1000px) {
    .about {
        flex-direction: column;
        align-items: center;
    }

    .about .about-img {
        width: 100%;
        margin-bottom: 50px;
    }

    .about .about-content {
        width: 100%;
    }

}

@media (max-width: 800px) {
    .about .about-img .img-s {
        left: 240px;
    }

    .about .about-content h3 {
        font-size: 36px;
    }

    .about .about-content p {
        font-size: 16px;
    }
}

@media (max-width: 640px) {
    .about {
        padding: 100px 40px;
    }

    .about .about-img .img-s {
        left: 220px;
    }

    .about .about-content h3 {
        font-size: 34px;
    }
}

@media (max-width: 480px) {
    .about .about-img .img-b {
        width: 230px;
    }

    .about .about-img .img-s {
        left: 160px;
        width: 120px;
        bottom: 40px;
    }

    .about .about-content h3 {
        font-size: 24px;
    }

    .about .about-content p {
        font-size: 14px;
    }
}