.spotlight {
    background: url(../../Assets/Images/spot.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spotlight .spotlight-content {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 100px 0;
}

.spotlight .spotlight-content .content {
    width: 45%;
}

.spotlight .spotlight-content .content h6 {
    color: #fff;
    background-color: #ffffff3a;
    padding: 4px;
    width: 50%;
    margin: 0 auto 20px;
    font-size: 14px;
}

.spotlight .spotlight-content .content h1 {
    color: #fff;
    font-size: 60px;
    margin-bottom: 20px;
}

.spotlight .spotlight-content .content h1 span {
    color: #04a472;
}

.spotlight .spotlight-content .content p {
    color: #fff;
    margin: 0 auto 20px;
    font-size: 18px;
    line-height: 1.5em;
    text-transform: uppercase;
}

.spotlight .spotlight-content .content button.explore {
    color: #fff;
    background: linear-gradient(90deg, rgba(4, 164, 114, 1) 0%, rgba(0, 6, 12, 1) 94%);
    padding: 18px 30px;
    display: inline-block;
    border: none;
    font-size: 16px;
    letter-spacing: 1px;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 #fff;
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-out 1s;
}

.spotlight .spotlight-content .content button.explore:hover {
    color: #000;
    box-shadow: inset 400px 50px 0 0 #fff;
}

.whatsapp a {
    color: #25D366;
    font-size: 60px;
    position: fixed;
    right: 50px;
    bottom: 20px;
    z-index: 1;
}


@media (max-width: 1280px) {
    .spotlight .spotlight-content .content {
        width: 65%;
    }
}

@media (max-width: 960px) {
    .spotlight .spotlight-content .content {
        width: 76%;
    }
}

@media (max-width: 800px) {
    .spotlight .spotlight-content .content h1 {
        font-size: 50px;
    }

    .spotlight .spotlight-content .content {
        width: 80%;
    }

    .spotlight .spotlight-content .content p {
        width: 80%;
    }
}

@media (max-width: 640px) {
    .spotlight .spotlight-content .content {
        width: 100%;
    }

    .spotlight .spotlight-content .content h6 {
        width: 50%;
    }

    .spotlight .spotlight-content .content h1 {
        font-size: 40px;
    }

    .spotlight .spotlight-content .content p {
        font-size: 14px;
    }

    .whatsapp a {
        right: 20px;
        font-size: 50px;
    }

}

@media (max-width: 480px) {
    .spotlight .spotlight-content .content h1 {
        font-size: 30px;
    }

    .spotlight .spotlight-content .content h6 {
        width: 56%;
        font-size: 12px;
    }
}