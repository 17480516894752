.achievement {
    /* background: url(../../Assets/Images/case-two-bg.png.webp);
    background-repeat: no-repeat; */
    background-color: #0A0A0A;
    height: 80vh;
    background-size: contain;
    padding: 100px 40px;
}

.achievement .top-case {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.achievement .top-case img {
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.achievement .top-case h6 {
    color: #04a472;
    font-size: 18px;
}

.achievement h3 {
    font-size: 40px;
    color: #fff;
}

.achievement .top-achive {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;
    padding: 0 50px;
}

.achievement .top-right {
    display: flex;
}

.achievement .top-right .round {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border: 1px solid #04a472;
    margin-right: 20px;
}

.achievement .top-right .bg-round {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #04a472;
}


.achievement .card-list .card {
    width: 86% !important;
    background-color: #000;
    border-radius: 30px;
    border: 2px solid #04a472;
}


.achievement .card-list .card .top-img img {
    display: block;
    width: 100%;
    height: 260px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.achievement .card-list .card .bottom-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.achievement .card-list .card .bottom-content h6 {
    font-size: 18px;
    color: #fff;

}

.achievement .card-list .card .bottom-content svg {
    color: #fff;
    font-size: 18px;
    border: 1px solid #fff;
    border-radius: 30px;
    padding: 6px;
}


@media (max-width: 800px) {
    .achievement .top-case h6 {
        font-size: 16px;
    }

    .achievement .top-case img {
        width: 25px;
        height: 22px;
        margin-right: 10px;
    }

    .achievement h3 {
        font-size: 36px;
    }
}

@media (max-width: 640px) {
    .achievement .top-right {
        display: none;
    }
}


@media (max-width: 480px) {
    .achievement .top-case img {
        width: 20px;
        height: 16px;
    }

    .achievement h3 {
        font-size: 30px;
    }

    .achievement .top-achive {
        padding: 0;
    }
}